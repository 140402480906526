<template>
  <table-view v-loading='loading' :show-page='false'>
    <template #header>
      <view-search-form
        :queryInfo.sync='queryInfo'
        :tool-list="['grade', 'college', 'major', 'class']"
        :insert-select-all="['college',  'major']"
        @on-search='renderTable' />
    </template>
    <el-row>
      <el-col :span='8'>
        <school-card-temp title='鞋码数统计' :card-content='formData.shoe' />
      </el-col>
      <el-col :span='8'>
        <school-card-temp title='下衣码数统计' :card-content='formData.coat' />
      </el-col>
      <el-col :span='8'>
        <school-card-temp title='上衣码数统计' :card-content='formData.pant' />
      </el-col>
    </el-row>
  </table-view>
</template>
<script>
import { schoolCoatCount } from '@/api/student'
import tableView from '@/vue/mixins/table-view'
import SchoolCardTemp from '@/views/pages/studentDepartment/dialog/SchoolCardTemp.vue'

export default {
  name: 'schoolCoatCount',
  components: { SchoolCardTemp },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: null,
        grade: new Date().getFullYear(),
        collegeId: null,
        majorId: null,
        classesId: null
      },
      formData: {
        shoe: {},
        coat: {},
        pant: {}
      }
    }
  },
  methods: {
    renderTable () {
      schoolCoatCount(this.queryInfo).then(res => {
        ({ shoe: this.formData.shoe, coat: this.formData.coat, pant: this.formData.pant } = res.data)
      })
    }
  }
}
</script>
