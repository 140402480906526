<template>
  <el-card class='school-card-temp'>
    <div slot='header' class='clearfix'>
      <span>{{ title }}</span>
    </div>
      <p v-for='(value,key) in cardContent' :key='key' class='text item'>
        {{ key + '码：' + value + '人' }}
      </p>
  </el-card>
</template>

<script>
export default {
  name: 'SchoolCardTemp',
  props: {
    title: String,
    cardContent: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  mounted () {
  },
  methods: {}
}
</script>

<style lang='scss'>
.school-card-temp {
  margin: 10px;
}
</style>
